import React, { useState, useEffect,useMemo } from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";
import toastr from "toastr";
import {
  parseUnits,
  formatEther,
  BrowserProvider,
  Contract,
  JsonRpcProvider,
  parseEther,
} from "ethers";
import { copyUrl } from "../../constant/BaseUrl";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button as Btn } from "react-bootstrap";
import { Button } from "./partials/Button";
import { binanceChainId, bscRpcUrl, explorerUrlBsc } from "../../constant";

import {
  getDirectIncome,
  getPackageReport,
  referralCode,
  getRoi,
  withdrawalAdd,
  AvailableBalance,
  profileGet,
} from "../../services/user";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../widgets/Sidebar";

import copy from "copy-to-clipboard";
import { InputValid } from "../../validations/InputValid";
import { getTeamBusiness } from "../../services/team";
export const Dashboard = () => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [currentPackage, setCurrentPackage] = useState("");
  const [totalDirectIncome, settotalDirectIncome] = useState("");
  const { walletProvider } = useWeb3ModalProvider();
  const [referral_code, setReffral_code] = useState("");
  const [totalRoi, setTotalRoi] = useState("");
  const [totalLevelRoi, setTotalLevelRoi] = useState("");
  const [copyText, setCopyText] = useState("Copy Link");
  const [copyReferral_code, setCopyReferral_code] =
    useState("Copy Referral Code");
  const navigate = useNavigate();
  const projectId = "1d8e553b04494fc86b01065d081d9e93";
  const [record, setRecord] = useState([]);
  const [rank, setRank] = useState({});
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [availableBalances, setavailableBalances] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [sponsorId, setSponsorId] = useState("");
  const [btn, setbtn] = useState(false);
  const [totalPackageAmount, setTotalPackageAmount] = useState("");
  const [totalTeam, setTotalTeam] = useState("");
  const [levelReport, setlevelReport] = useState([]);
  const [profileRecord, setProfileRecord] = useState([])
  const [totalDirect, setTotalDirect] = useState(0)
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const mainnet = [
    {
      chainId: binanceChainId,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: explorerUrlBsc,
      rpcUrl: bscRpcUrl,
    },
  ];

  const metadata = {
    name: "assetplus",
    description: "ukc",
    url: "https://assetplus.com/",
    icons: ["https://assetplus.com/html/images/Logo.png"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  const handleShow = async () => {
    setTimeout(() => {
      setShow(true);
    }, 2000);
  };

  const handleClose = () => {
    setShow(false);
    setAmountErr("");
    setAmount("");
  };

  const getReferralCodeHandler = async () => {
    const data = { wallet_address: address };
    const res = await referralCode(data);
    if (res.status) {
      setReffral_code(res?.referral_code);
    }
  };

  const getTotalRoiHandler = async () => {
    const token = localStorage.getItem("jwtToken");
    const data = {};
    const res = await getRoi(data, token);
    if (res.status) {
      setTotalRoi(res?.totalRoiIncome);
      setTotalLevelRoi(res?.totalRoiLevelIncome);
    }
  };

  const AvailableBalanceHandler = async () => {
    const token = localStorage.getItem("jwtToken");
    const res = await AvailableBalance(token);

    if (res.status) {
      setavailableBalances(parseInt(Number(res?.balance) * 10000) / 10000);
      setTotalBalance(res?.totalBalance);
    }
  };

  const profileData = async () => {
    const data = { address };
    const token = localStorage.getItem("jwtToken");
    const resp = await profileGet(data, token);

    if (resp.status) {
      setSponsorId(resp?.sponsorId);
      setProfileRecord(resp?.data[0])
      setTotalDirect(resp?.totalDirect)
    }
  };

  const copyToClipboard = (address, type) => {
    if (address) {
      if (type == "link") {
        copy(copyUrl + referral_code);
        setCopyText("Copied");
        setTimeout(() => {
          setCopyText("Copy Link");
        }, 2000);
      } else {
        copy(referral_code);
        setCopyReferral_code("Copied");
        setTimeout(() => {
          setCopyReferral_code("Copy Referral Code");
        }, 2000);
      }
    }
  };

  useEffect(() => {
    getReferralCodeHandler();
    getTotalRoiHandler();
    profileData();
  }, [address]);

  useEffect(() => {
    const getWithdrawalDatasData = async () => {
      const address = localStorage.getItem("wallet_address");
      const data = { address };

      const result = await getPackageReport(data);

      if (result?.status) {
        setRank(result?.rank);

        setRecord(result?.data[0]);
      }
    };
    getWithdrawalDatasData();
  }, [address]);

  const getPackageLabel = (packageId) => {
    switch (packageId) {
      case 1:
        return "Basic";
      case 2:
        return "Advance";
      case 3:
        return "Premium";
      case 4:
        return "Elite";
      default:
        return "No package";
    }
  };

  useEffect(() => {
    const getReferralData = async () => {
      const config = localStorage.getItem("jwtToken");
      const datas = {};

      const result = await getDirectIncome(datas, config);

      if (result?.status) {
        settotalDirectIncome(result?.sumDirectIncome);
      }
    };
    getReferralData();
    AvailableBalanceHandler();
  }, [address]);

  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name == "amount") {
      // setAmount(value);
      if (/^\d*\.?\d{0,4}$/.test(value)) {
        setAmount(value);
      }
      const err = InputValid(name, value);
      setAmountErr(err);
    }
  };

  const WithdrawalHandler = async () => {
    const checkQuestion = InputValid("amount", amount);
    if (checkQuestion) {
      setAmountErr(checkQuestion);
      return false;
    }

    const data = { amount };
    const token = localStorage.getItem("jwtToken");
    const res = await withdrawalAdd(data, token);
    setbtn(true);
    if (res.status) {
      toastr.success(res.message);
      // getData();
      AvailableBalanceHandler();
      handleClose();
      setbtn(false);
    } else {
      setbtn(false);
      toastr.error(res.message);
    }
  };

  const handleBlur = () => {
    if (amount) setAmount(parseFloat(amount).toFixed(4));
  };

  useEffect(() => {
    const getBusinessData = async () => {
      const address = localStorage.getItem("wallet_address");
      const token = localStorage.getItem("jwtToken");
      const data = { address };

      const result = await getTeamBusiness(data, token);

      if (result?.status) {
        setlevelReport(result?.level);
        setTotalPackageAmount(result?.totalPackageAmount);
        setTotalTeam(result?.teamCount);

        // setTeam(
        //   result.team.map((d) => ({
        //     count: d.length,
        //     total: d.reduce((t, s) => t + Number(s.packageAmount), 0),
        //     team: d,
        //   }))
        // );
      }
    };
    getBusinessData();
  }, []);

  useEffect(() => {
   
  if(profileRecord?.booster_end_date){

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const startTime = new Date(profileRecord?.booster_end_date).getTime();
      let t;
      t = startTime - now;
      if (t <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(t / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((t % (1000 * 60)) / 1000);
        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }
  
}, [profileRecord]);

const memoizedTimeLeft = useMemo(() => {
  return timeLeft;
}, [timeLeft]);

  return (
    <>
      <div className="dash_bar_man">
        <Sidebar />
        <div className="d-md-flex mb-2 mb-md-4 align-items-center">
          {/* <div className="img_box ml-auto d-inline-flex align-items-center order-md-2">
            <h6 className="mr-md-4 mr-2 mb-0">Your Wallet Address: </h6>
            <span className="color1 pl-2 mr-2 ">
              <w3m-button balance="hide" />
            </span>
            <img alt className="max-h-full" src="/assets/img/user.png" />
          </div> */}

          <Button />
          <h2 className="order-md-1 in_hadding">Dashboard </h2>
        </div>

        <div className="right_bar">
          <div className="row row5">
            <div className="col-md-4 mb-4 mb-md-55">
              <div className="dash_box h100 active">
                <span className="das_icon">
                  <i className="bi bi-currency-dollar" />
                </span>
                <div className="d-flex align-items-end">
                  <div className>
                    <h6 className="d_hadding mt-4">
                      Current Package: {getPackageLabel(record?.packageId)}
                    </h6>
                    <h4 className="mb-0">${record?.packageAmount}</h4>
                  </div>
                  <img src="/assets/img/ch.png" className="ch ml-auto" />
                </div>
              </div>
            </div>
           
            <div className="col-md-4 mb-4 mb-md-55">
              <div className="dash_box h100">
                <span className="das_icon">
                  <i className="bi bi-wallet2" />
                </span>
                <div className="d-flex     align-items-end">
                  <div className>
                    <h6 className="d_hadding mt-4">Available Balance</h6>
                    <h4 className="mb-0">
                      $
                      {availableBalances
                        ? Number(availableBalances).toFixed(4)
                        : "0"}
                    </h4>
                    <h6 className="d_hadding mt-4">Total Balance</h6>
                    ${totalBalance
                      ? (
                          parseInt(Number(totalBalance) * 10000) / 10000
                        ).toFixed(4)
                      : "0"}
                  </div>
                  <button
                    className="btn btn_man btn_man_small ml-auto"
                    onClick={() => handleShow()}
                    disabled={btn}
                  >
                    <span>Withdraw</span>
                  </button>
                </div>
              </div>
            </div>
          <div className="col-md-4 mb-4 mb-md-55">
                  <div className="dash_box h100 ">
                  <span className="das_icon">
                  <i class="bi bi-rocket-takeoff"></i>
                </span>
                    <div className="">
                      <div className>
                        <h6 className="d_hadding mt-4">Booster Active </h6>
                         {profileRecord?.status=="Active" &&( timeLeft?.days==0 && timeLeft?.hours==0 && timeLeft?.minutes==0 && timeLeft?.seconds==0 ?<div>
                          Boster {totalDirect>=7?"Activated":"InActivated"}
                         </div>: <div className="time-flex">
                          <div>
                          <span>{memoizedTimeLeft?.days}</span>
                          <p>Days</p>
                          </div>
                         <div>
                         <span>{memoizedTimeLeft?.hours}</span>
                         <p>Hours</p>
                         </div>
                          <div>
                          <span>{memoizedTimeLeft?.minutes}</span>
                          <p>Min</p>
                          </div>
                          <div>
                          <span>{memoizedTimeLeft?.seconds}</span>
                          <p>Sec</p>
                          </div>
                     
                         </div>)
                         }
                      </div>
                     
                    </div>
                  </div>
                </div> 
            
            
          </div>
          <div className="row ">
            {/* <div className="col-md-8">
              <div className="dash_box h100 ">
                <img src="/assets/img/chart.png" className="w100 " />
              </div>
            </div> */}
            <div className="col-md-4">
              <div className="dash_box">
                {" "}
                <div className="d-md-flex align-items-center">
                  <h4 className="mr-auto">Sponsor Id</h4> <h6>{sponsorId}</h6>
                </div>
                <h6 className="d_hadding mb-2 mt-2">Referral link </h6>
                <div className="d-flex align-items-center">
                  <p className="color1 mr-2 wbba lh1_3 mt-0">
                    {!referral_code
                      ? "referral link generated after the package buy"
                      : copyUrl + referral_code}
                    {/* {copyUrl + referral_code} */}
                  </p>

                  {referral_code ? (
                    <button
                      className="btn btn_man btn_man_small ml-auto"
                      onClick={() => {
                        copyToClipboard(address, "link");
                      }}
                    >
                      <i className="bi bi-copy mr-2"></i> {copyText}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                {referral_code ? (
                  <button
                    className="btn btn_man btn_man_small ml-auto"
                    onClick={() => {
                      copyToClipboard(address, "referral");
                    }}
                  >
                    <i className="bi bi-copy mr-2"></i> {copyReferral_code}
                  </button>
                ) : (
                  ""
                )}
                <hr />
                <h6 className="d_hadding mb-3">Total ROI</h6>
                <h5 className>
                  ${totalRoi ? Number(totalRoi).toFixed(4) : "0"}
                </h5>
                <hr />
                <h6 className="d_hadding mb-3">Total Referral Income</h6>
                <h5 className>
                  $
                  {totalDirectIncome
                    ? Number(totalDirectIncome).toFixed(4)
                    : "0"}
                </h5>
                <hr />
                <h6 className="d_hadding mb-3">Total Level Income</h6>
                <h5 className>
                  ${totalLevelRoi ? Number(totalLevelRoi).toFixed(4) : "0"}
                </h5>
                <hr />
                <h6 className="d_hadding mb-3">Total Rank Income</h6>
                <h5 className>
                  ${rank[0]?.total_amount ? rank[0]?.total_amount : "0"}
                </h5>
              </div>
            </div>
            <div className="col-md-8 mb-4 mb-md-55">
              <div className="row">
                <div className="col-md-6 mb-4 mb-md-55">
                  <div className="dash_box">
                    <span className="das_icon">
                    <i class="bi bi-currency-dollar"></i>
                    </span>
                    <div className="d-flex  align-items-end">
                      <div className>
                        <h6 className="d_hadding mt-4"> Total team business </h6>
                        <h4 className="mb-0">$ {totalPackageAmount}</h4>
                      </div>
                      <img src="/assets/img/ch.png" className="ch ml-auto" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4 mb-md-55">
                  <div className="dash_box">
                    <span className="das_icon">
                    <i class="bi bi-people"></i>
                    </span>
                    <div className="d-flex  align-items-end">
                      <div className>
                        <h6 className="d_hadding mt-4">Total Team </h6>
                        <h4 className="mb-0">{totalTeam}</h4>
                      </div>
                      <img src="/assets/img/ch.png" className="ch ml-auto" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4 mb-md-55">
                  <div className="dash_box">
                    <span className="das_icon">
                    <i class="bi bi-people"></i>
                    </span>
                    <div className="d-flex  align-items-end">
                      <div className>
                        <h6 className="d_hadding mt-4">Direct Team </h6>
                        <h4 className="mb-0">
                          {levelReport[1]?.length
                            ? levelReport[1]?.length
                            : "0"}
                        </h4>
                      </div>
                      <img src="/assets/img/ch.png" className="ch ml-auto" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4 mb-md-55">
              <div className="dash_box ">
                <span className="das_icon">
                  <i className="bi bi-bar-chart" />
                </span>
                <div className="d-flex  align-items-end">
                  <div className>
                    <h6 className="d_hadding mt-4">Rank</h6>
                    <h4 className="mb-0">
                      {rank[0]?.rankName ? rank[0]?.rankName : "0"}
                    </h4>
                  </div>
                  <img src="/assets/img/ch.png" className="ch ml-auto" />
                </div>
              </div>
            </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              <div className="dash_box">
                <h6>Total team business :{totalPackageAmount}</h6>
              </div>
              <div className="dash_box">
                <h6>Your Team :{totalTeam}</h6>
              </div>
              <div className="dash_box">
                <h6> Direct Team :{levelReport[1]?.length}</h6>
              </div>
              
            </div> */}
          </div>
          <div className="row "> </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label> Amount </Form.Label>
              <Form.Control
                name="amount"
                onChange={handlechange}
                type="text"
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                value={amount}
                onBlur={handleBlur}
              ></Form.Control>
              <span style={{ color: "red" }}>{amountErr}</span>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Btn variant="secondary" onClick={handleClose}>
            Close
          </Btn>
          <Btn variant="primary" onClick={() => WithdrawalHandler()}>
            Submit
          </Btn>
        </Modal.Footer>
      </Modal>
    </>
  );
};
