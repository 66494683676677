import React, { useEffect, useState } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import { getFilterData, getPackageReport } from "../../services/user";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../widgets/Sidebar";
import { Button } from "./partials/Button";
import { getTreeTeam } from "../../services/team";

export const Genology = () => {
  const [record, setRecord] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    getTreeTeamData();
  }, []);
  console.log(record, "record===");

  const getTreeTeamData = async () => {
    const token = localStorage.getItem("jwtToken");

    const data = {};

    const result = await getTreeTeam(data, token);

    if (result) {
      setRecord(result);
    }
  };
  const getTreeTeamDatas = async (id) => {
    const token = localStorage.getItem("jwtToken");

    const data = { id };

    const result = await getTreeTeam(data, token);

    if (result) {
      setRecord(result);
    }
  };

  const handleSearch = async () => {
    if (searchTerm) {
      const token = localStorage.getItem("jwtToken");

      const data = { value: searchTerm };

      const result = await getFilterData(data, token);

      if (result.status) {
        setFilterData(result.data);
        setShowDropdown(result.data.length > 1);
        if (result?.data?.length == 1) {
          const token = localStorage.getItem("jwtToken");
          const id = result?.data[0]?.id;
          const data = { id };
          const results = await getTreeTeam(data, token);

          if (results) {
            setRecord(results);
          }
        }
      }
    } else {
      setFilterData([]);
      setShowDropdown(false);
    }
  };
  const handleSelect = async (event) => {
    const selectedId = event.target.value;

    const token = localStorage.getItem("jwtToken");
    const id = selectedId;
    const data = { id };
    const results = await getTreeTeam(data, token);

    if (results) {
      setRecord(results);
    }
  };
  return (
    <>
      <div className="dash_bar_man">
        <Sidebar />
        <div className="d-md-flex mb-2 mb-md-4 align-items-center">
          <Button /> <h2 className="in_hadding order-md-1">Genealogy</h2>
        </div>

        <div className="dash_box">
          <div className="d-flex mb-4">
            <button
              className="btn btn_man btn_man_small mr-2"
              onClick={() => getTreeTeamData()}
            >
              <span>Reset</span>
            </button>

            <div className="text-let d-flex  ml-auto">
              <input
                className="form-control form-control22"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Enter search term"
              />
              <button
                onClick={handleSearch}
                className="btn btn_man btn_man_small ml-2"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </div>

          {/* {showDropdown && (
            <select onChange={(e)=>handleSelect()} defaultValue="">
              <option value="" disabled>
                Select an option
              </option>
              {filterData.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name} ---- {item.registerId} - {item.wallet_address}
                </option>
              ))}
            </select>
          )} */}

          {showDropdown && (
            <select onChange={handleSelect} defaultValue="">
              <option value="" disabled>
                Select an option
              </option>
              {filterData.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name} ---- {item.registerId} - {item.wallet_address}
                </option>
              ))}
            </select>
          )}

          {/* {!showDropdown && filterData.length === 1 && (
            <div>
              <p>
                <strong>{filterData[0]?.name}</strong>
                {filterData[0]?.registerId} - {filterData[0]?.wallet_address}
              </p>
            </div>
          )} */}
          <div className="network">
            <div className="genealogy-tree">
              <ul style={{}}>
                <li>
                  <a href="javascript:void(0);">
                    <div className="member-view-box">
                      <div className="details">
                        <h2>{record?.selfData?.name} ( {record?.selfData?.status})</h2>
                        <div
                          className="details-text"
                          
                        >
                          <p>{record?.selfData?.wallet_address} </p>
                        </div>
                        <div
                          className="details-text"
                          
                        >
                          <p>Total Team Business : {record?.selftotalBusiness} </p>
                        </div>
                      </div>
                      <div className="member-header">
                        <span>Leader</span>
                      </div>
                      <div className="member-image">
                        <img src="https://growtreecoin.io/images/default-image.jpg" />
                      </div>
                      <div className="member-footer">
                        <div className="name">
                          <span>{record?.selfData?.name}</span>
                        </div>
                        <div className="downline">
                          <span>{record?.selfData?.registerId}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                  <ul className="active">
                    {record?.teamData?.map((item, i) => {
                      return (
                        <li>
                          <a
                            href="#"
                            onClick={() =>
                              getTreeTeamDatas(item?.directTeam[0]?.id)
                            }
                          >
                            <div className="member-view-box">
                              <div className="details">
                                <h2>{item?.directTeam[0]?.name}( {item?.directTeam[0]?.status})</h2>
                                <div className="details-text">
                                  <p>
                                    Wallet address-{" "}
                                    {item?.directTeam[0]?.wallet_address}
                                  </p>
                                  <p>
                                    Total package amount :{" "}
                                    {item?.directTeam[0]?.packageAmount}
                                  </p>
                                  <p>
                                    Total Team Business :{" "}
                                    {item?.totalBusiness}
                                  </p>


                                  {/* <p>Left Team -0</p>
                    <p>Right Team -0</p>
                    <p>Right Team Bussiness-0</p> */}
                                </div>
                              </div>
                              <div className="member-header">
                                <span>Leader</span>
                              </div>
                              <div className="member-image">
                                <img src="https://growtreecoin.io/images/default-image.jpg" />
                              </div>
                              <div className="member-footer">
                                <div className="name">
                                  <span>{item?.directTeam[0]?.name}</span>
                                </div>
                                <div className="downline">
                                  <span>
                                    Register Id :{" "}
                                    {item?.directTeam[0].registerId}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
